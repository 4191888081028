function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getTmsInstance } from 'integration-web-core--socle/js/assets/commons/_tms';

/**
 * This class makes Kobi lazy-loading of fragments compatible with data TMS.
 * It listens for 'fragmentLoaded' events and push data TMS client side when a fragment is lazy-loaded.
 */
export class TmsLazyLoadingBridge {
  constructor(fragmentIdRegExp) {
    /**
     * A pattern matching the fragment ids that will trigger a data TMS push when lazy-loaded.
     */
    _defineProperty(this, "fragmentIdRegExp", null);
    /**
     * The current listener for the 'fragmentLoaded' event.
     */
    _defineProperty(this, "fragmentLoadedListener", null);
    this.fragmentIdRegExp = fragmentIdRegExp;
  }

  /**
   * Start listening for 'fragmentLoaded' events.
   * If the listener is already started, it does nothing.
   * @returns {boolean} true if the listener has been started.
   */
  startListening() {
    if (this.fragmentLoadedListener) {
      return false;
    }
    const lazyLoadingEventBus = window['lazy-loading-bus'];
    if (!lazyLoadingEventBus) {
      console.warn('Lazy loading event bus not found');
      return false;
    }
    this.fragmentLoadedListener = event => this.handleFragmentLoadedEvent(event);
    lazyLoadingEventBus.on('fragmentLoaded', this.fragmentLoadedListener);
    return true;
  }

  /**
   * Stop listening for 'fragmentLoaded' events.
   * If the listener is not started, it does nothing.
   * @returns {boolean} true if the listener has been stopped.
   */
  stopListening() {
    if (!this.fragmentLoadedListener) {
      return false;
    }
    const lazyLoadingEventBus = window['lazy-loading-bus'];
    if (!lazyLoadingEventBus) {
      console.warn('Lazy loading event bus not found');
      return false;
    }
    lazyLoadingEventBus.off('fragmentLoaded', this.fragmentLoadedListener);
    this.fragmentLoadedListener = null;
    return true;
  }
  handleFragmentLoadedEvent(event) {
    const {
      fragmentId,
      htmlElements
    } = event.detail;
    if (this.fragmentIdRegExp && this.fragmentIdRegExp.test(fragmentId) && this.anyHtmlElementContainsDataTms(htmlElements)) {
      this.pushDataTmsClientSide();
    }
  }
  anyHtmlElementContainsDataTms(htmlElements) {
    for (const htmlElement of htmlElements) {
      if (htmlElement.querySelector(TmsLazyLoadingBridge.dataTmsSelector) !== null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Push data TMS client side.
   * This method should be called when a fragment is lazy-loaded.
   * It reads all the DOM, searching for .dataTms elements and merge them into the existing data layer.
   */
  pushDataTmsClientSide() {
    getTmsInstance().pushFragment([], false);
  }
}
/**
 * CSS selector for elements containing data TMS.
 */
_defineProperty(TmsLazyLoadingBridge, "dataTmsSelector", 'script.dataTms[type="application/json"]');
(function () {
  const tmsLazyLoadingBridge = new TmsLazyLoadingBridge(/com\.adeo\.website\.productrecommendation\..*/);
  tmsLazyLoadingBridge.startListening();
})();